import { type Accessor, onCleanup } from "solid-js";

declare module "solid-js" {
  namespace JSX {
    interface DirectiveFunctions {
      // use:clickOutside
      clickOutside: typeof clickOutside;
    }
  }
}

export function clickOutside(
  element: HTMLElement,
  accessor: Accessor<(() => void) | undefined>,
) {
  const onClick = (event: MouseEvent) => {
    if (!element.contains(event.target as Element)) {
      accessor()?.();
    }
  };

  document.body.addEventListener("click", onClick);

  onCleanup(() => {
    document.body.removeEventListener("click", onClick);
  });
}
